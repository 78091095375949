.date_picker_div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background-color: grey; */
}

.date_picker {
  width: 1000px;
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  display: none;
}

.rIcon {
  position: relative;
  left: -23px;
  z-index: 10;
  height: 1.3rem;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
