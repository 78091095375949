.antnoti{
    background-color: rgb(28,28,28)!important;
    color: white;
    z-index: 20000 !important;
  }

  .ant-notification-notice-close{
    color: white!important;
  }

  .ant-notification-notice-message{
    color: #fff!important;
  }