.annotClass {
    height: 5rem;
}

/* #SvgjsRect1047{
    height: 5rem;
    width: 7rem;
    position: relative;
} */

#SvgjsRect1054 {
    border-radius: 50%;
    position: absolute;
    top: 1px;
}

.popUpContainer {

    position: absolute;
    background-color: white;
    /* text-align: center; */
    color: black;
    z-index: 100;
    border-radius: 1rem;
    box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.45);
    /* display: flex;
    align-items: center; */

}

.popupLine {

    height: 80%;
    /* width: 1px; */
    border: 3px solid;
    margin: 1rem;
    border-radius: 1rem;
    align-items: flex-start;

}

.popupContent {
    width: 100%;
    height: 100%;
    /* align-items: center; */
}



.closeIcon {
    position: relative;
    top: 0rem;
    right: 0rem;
    cursor: pointer;
}

.yAxisAnnot {
    fill: red !important;
    height: 2rem !important;
    width: 2rem !important;
}

.svgClass {
    cursor: pointer;
}

.slick-prev {
    top: 95px !important;
    left: 10px !important;
}

.slick-next {
    top: 95px !important;
    right: 10px !important;
}

/* .apexcharts-text .apexcharts-xaxis-annotation-label .yAxisAnnot {
    cursor: pointer!important;
} */