.assembly
{
    background-color: white;
    cursor: pointer;
}
.assembly_selected
{
    background-color: #EEEFFF;
}
.assembly:hover
{
    background-color: #EEEFFF;
}

.BS1Style
{
    background-color: white;
    cursor: pointer;
}
.BS1Style_selected
{
    background-color: #E0E0E0;
}
.BS1Style:hover
{
    background-color: #E0E0E0;
}

.BS2Style
{
    background-color: white;
    cursor: pointer;
}
.BS2Style_selected
{
    background-color: rgb(184, 184, 255);
}
.BS2Style:hover
{
    background-color: rgb(184, 184, 255);
}

.batteryBox {
    transform: scale(1);
  
    transition-duration: 0.5s;
    /* border: 2px solid transparent; */
  }
  .batteryBox_selected {
    transform: scale(1.150);
    transition-duration: 0.3s;
    /* border: 2px solid black; */
  }
  
  .batteryBox:hover {
    transform: scale(1.150);
    transition-duration: 0.3s;
    /* border: 2px solid black; */
    /* background-color: blue; */
  }