.mainDiv{
    width: 100%;
    height: 100vh;
}
.notMainDiv
{
    height: 100%
}


.mapDiv{
    width: 100%;
    height: 100%;
   
}

.sideDivMap{
    /* width: 25%; */
    /* height: 100%; */
    display: flex;
    height: 5rem;
}

.loaderDiv{
    /* width: 100%; */
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 50%;
    top:40%;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.dropDownMap{
    display: flex;
    flex-direction: column;
    width: 7rem;
    /* z-index: 100; */
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
  

}

.dropDownBoxMap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.dropDowndDivMap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.itemDivMap{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    height: 1.5rem;
}
.itemDivMap p{
    margin-left: 0.8rem;
    text-transform: capitalize;
}
.locationDropdownButton{
    width: 8.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 5px;
    display: inline-block;
}