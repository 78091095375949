.login {

    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 5rem;
    height: 60vh;

    align-items: center;
    box-sizing: border-box;
}

.loginFields {
    border-radius: 1rem !important;
}

.box {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    padding: 5rem 2rem;
    /* background-color: #dddada; */
    border: 1px solid #bfbfbf;
    border-radius: 1rem;
    width: 28rem;
    justify-content: center;
    align-items: center;
    /* height: 10rem; */

}

.box img {
    height: 3rem;
    width: 3rem;
    position: relative;
    top: -2rem;
    /* right: -6rem; */

}

.button {
    width: 100% !important;
    border-radius: 0.5rem !important;
    margin-bottom: 1rem !important;
}

.button.resetPasswordBtn {
    margin-top: 2rem !important;


}

.input {
    background-color: white;
    border-radius: 0.5rem !important;
    width: 100%;
    border-color: #ef5350 !important;
}

.forgot {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.forgot p {

    margin: 0;
    margin-bottom: 2rem;
    color: #6e6464;
    cursor: pointer;
}

.forgot .error {
    margin: 0;
    margin-bottom: 2rem;
    cursor: pointer;
    color: #ef5350;

}

.forgot p {
    cursor: pointer;
}

.messageBox {

    margin: 1rem;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    background-color: white;
    width: 100%;
    justify-content: center;
    display: inline;
                    text-align:center;
                    padding: 10px
}

.invalidLink {
    margin: 1rem 0;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid white;

}

.passChangedSuccess {
    margin: 1rem 0;
    /* padding: 0.5rem; */
    border-radius: 1rem;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid white;

}

.messageBox.error {
    border: 1px solid #ef5350;
}


.messageBox.info {
    border: 1px solid blue;
}


.messageBox.success {
    border: 1px solid green;
}



.successBox {
    border: 1px solid green;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: white;
}

.successBox p {
    color: green;
}

.resetHeading {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 0;
}